import { Link } from "react-router-dom";
import styled from "styled-components";
import { Mark, Selection } from "../@interfaces/interfaces";
import { useParams } from "react-router-dom";
import { useRef, useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin-top: 1em;

  .no-shrink {
    flex-shrink: 0;
  }

  .title--box {
    background-color: #417690;
    color: #f5dd5d;
    padding: 0.5em;
    margin-bottom: 0em;
  }
`;

const ScrollContent = styled.div`
  display: grid;
  align-items: center;
  box-sizing: border-box;
  grid-template-rows: repeat(auto-fill);
  overflow: scroll;
  width: 100%;

  .mark {
    height: 20px;
    display: flex;
    gap: 0.5em;
  }

  .mark--disabled {
    font-style: italic;
    background-color: gray;
    color: white;
    border-radius: 4px;
    font-size: 0.8rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    align-self: center;
  }

  .mark--empty {
    font-style: italic;
    background-color: rgb(96, 154, 182);
    color: white;
    border-radius: 4px;
    font-size: 0.8rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    align-self: center;
  }

  .mark--current {
    a {
      background-color: #609ab6;
      color: white;
      border-radius: 4px;
    }
  }
  .link {
    padding: 2px 4px;
  }

  .link--reset {
    color: inherit;
    text-decoration: none;
  }
  .length {
    font-size: 0.8em;
    font-weight: 200;
    display: flex;
    margin-left: auto;
    justify-content: center;
    align-items: center;
  }
`;

interface SequenceNavProps {
  markList: Mark[];
  selections: Selection[];
}

function SequenceNav({ markList, selections }: SequenceNavProps) {
  const { frame } = useParams<{ frame: string }>();
  const { sequence_name } = useParams<{ sequence_name: string }>();

  const scrollRef = useRef<HTMLDivElement>(null);

  const sortedMarkList = markList.sort((a, b) => {
    const aNumber = parseInt(a.name.split("-frame")[1], 10);
    const bNumber = parseInt(b.name.split("-frame")[1], 10);
    return aNumber - bNumber;
  });

  useEffect(() => {
    // scroll to current frame
    const currentFrame = document.querySelector(".mark--current");
    if (currentFrame && scrollRef.current) {
      currentFrame.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <Wrapper>
      <div className="no-shrink title--box">{sequence_name}</div>
      <ScrollContent ref={scrollRef} id="sequence_nav">
        {sortedMarkList.map((mark, i) => {
          return (
            <div
              className={`mark ${mark.marked ? "" : ""} ${
                frame === mark.name ? "mark--current" : ""
              }`}
              key={i}
            >
              <Link
                className="link link--reset"
                to={`/${sequence_name}/${mark.name}`}
              >
                {mark.name.replace("image", "sequence").split("-")[0]}
              </Link>
              {mark.marked && <span className="mark--disabled">Checked</span>}
              {mark.empty_count > 0 && (
                <span className="mark--empty">{mark.empty_count} empty</span>
              )}

              <div className="length">{mark.length} frames</div>
            </div>
          );
        })}
      </ScrollContent>
    </Wrapper>
  );
}

export default SequenceNav;
